import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const HeaderContainer = styled.header`
  background-color: #000;
  .site-nav {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    nav {
      color: var(--cream);
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 4rem;
      padding: 0;
      width: 50%;
      @media only screen and (max-width: 468px) {
        width: 100%;
        text-align: center;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        column-gap: 0;
      }
      .nav-item {
        cursor: pointer;
        &:hover {
          color: var(--cream-focus);
        }
      }
      @media only screen and (max-width: 1024px) {
        column-gap: 3rem;
        font-size: 1.5em;
        .site-logo {
          img {
            height: 100px;
            width: auto;
          }
        }
      }
      @media only screen and (max-width: 468px) {
        column-gap: 2rem;
        font-size: 1.5em;
        .site-logo {
          img {
            height: 75px;
            width: auto;
          }
        }
      }
    }
  }
`;

interface Props {
  path: string;
  scrollTo: (el: string) => void;
}

const Header: React.FC<Props> = ({ path, scrollTo }) => {
  return (
    <HeaderContainer className="site-header">
      <div className="site-nav">
        <nav>
          {path === '/' && (
            <div className="nav-item" onClick={() => scrollTo('#Menu')}>
              MENU
            </div>
          )}
          <div className="site-logo">
            <StaticImage src="../images/sabor-cito-logo.png" alt="Logo" height={125} />
          </div>
          {path === '/' && (
            <div className="nav-item" onClick={() => scrollTo('#Location')}>
              HOURS
            </div>
          )}
        </nav>
      </div>
    </HeaderContainer>
  );
};

export default Header;
