import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import SocialList from './Social-List';

const FooterContainer = styled.footer`
  background-color: #000;
  padding: 3rem 0;
  text-align: center;
  .footer-title {
    height: 58px;
    width: auto;
    margin-bottom: 2rem;
  }
  .content {
    // width: 80%;
    // max-width: 1200px;
    // margin: auto;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 468px) {
      flex-direction: column;
      gap: 1rem;
    }
  }
  .footer-menu {
    flex: 1;
    color: var(--orange);
    text-align: left;
    @media only screen and (max-width: 468px) {
      text-align: center; 
    }
    ul {
      text-transform: uppercase;
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
    li {
      list-style: none;
      cursor: pointer;
      &:hover {
        color: var(--orange-focus);
      }
      a {
        text-decoration: none;
        color: var(--orange);
        &:hover {
          color: var(--orange-focus);
        }
      }
    }
  }
  .social {
    flex: 1;
    color: var(--cream);
    text-align: right;
    text-transform: uppercase;
    @media only screen and (max-width: 468px) {
      text-align: center;
    }
    h3 {
      font-size: 1.75rem;
      font-weight: bold;
    }
    ul {
      li {
        display: inline;
        padding-left: 2rem;
        @media only screen and (max-width: 468px) { 
          padding: 0 .5rem;
        }
    }
  }
  .copyright {
    color: var(--cream);
    font-size: 1.3rem;
    text-align: right;
    @media only screen and (max-width: 468px) {
      text-align: center;
    }
  }
`;

interface Props {
  path: string;
  scrollTo: (el: string) => void;
}

const Footer: React.FC<Props> = ({ path, scrollTo }) => (
  <FooterContainer className="site-footer">
    <div className="page-width">
      <StaticImage
        className="footer-title"
        src="../images/it-means-tasty-cream.png"
        alt="It Means Tasty!"
        height={60}
      />
      <div className="content">
        <div className="footer-menu">
          {path === '/' ? (
            <ul>
              <li onClick={() => scrollTo('#Menu')}>Menu</li>
              <li onClick={() => scrollTo('#Location')}>Location</li>
              <li onClick={() => scrollTo('#Contact')}>Contact</li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link to="/#Menu">Menu</Link>
              </li>
              <li>
                <Link to="/#Location">Location</Link>
              </li>
              <li>
                <Link to="/#Contact">Contact</Link>
              </li>
            </ul>
          )}
        </div>
        <div className="social">
          <h3>Follow Us</h3>
          <SocialList />
          <p className="copyright">© {new Date().getFullYear()} Suavecito, Inc.</p>
        </div>
      </div>
    </div>
  </FooterContainer>
);

export default Footer;
