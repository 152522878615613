import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const ListContainer = styled.div`
  ul {
    margin: 1rem 0;
    li {
      list-style: none;
      display: inline-block;
    }
  }
`;

const SocialList = () => {
  const data = useStaticQuery(query);
  return (
    <ListContainer>
      <ul>
        <li>
          <a href={data.dataJson.social.instagram} target="_blank">
            <StaticImage
              src="../images/icon-instagram.png"
              alt="Follow us on Instagram!"
              height={50}
            />
          </a>
        </li>
        <li>
          <a href={data.dataJson.social.yelp} target="_blank">
            <StaticImage src="../images/icon-yelp.png" alt="Find us on Yelp!" height={50} />
          </a>
        </li>
      </ul>
    </ListContainer>
  );
};

export default SocialList;

const query = graphql`
  query SocialListQuery {
    dataJson {
      social {
        instagram
        yelp
      }
    }
  }
`;
