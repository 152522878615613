import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import './reset.css';
import './global.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

interface Props {
  location?: any;
}

const isBrowser = typeof window !== 'undefined';
if (isBrowser) smoothscroll.polyfill();

const Layout: React.FC<Props> = ({ location, children }) => {
  const handleScrollTo = (el: string) => {
    const element = document.querySelector(el);
    element?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <main>
      <Header scrollTo={handleScrollTo} path={location.pathname} />
      {children}
      <Footer scrollTo={handleScrollTo} path={location.pathname} />
    </main>
  );
};

export default Layout;
