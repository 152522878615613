import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

interface Props {
  description?: string;
  lang?: string;
  meta?: any[];
  title: string;
  isIndex?: boolean;
}

const Seo: React.FC<Props> = ({ description, lang, meta, title, isIndex = false }) => {
  const { site } = useStaticQuery(query);

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  const titleTemplate = () => {
    if (defaultTitle) {
      if (isIndex) {
        return `${defaultTitle} | %s`;
      } else {
        return `%s | ${defaultTitle}`;
      }
    } else {
      return undefined;
    }
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate()}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta ? meta : [])}
    />
  );
};

export default Seo;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
